import { navigateTo } from '../http/redirect';
import { getPagePopupContainer } from './page-popup';
import { anyPopupOpen, closeAllUtilityPopups, utilityPopupOpen } from './utils';

function handleKeyboardEvent( event: KeyboardEvent ): void {
    if ( event.key !== 'Escape' || !anyPopupOpen() ) {
        return;
    }

    if ( utilityPopupOpen() ) {
        handleUtilityPopupClose();
        return;
    }

    handlePagePopupClose();
}

function handleMouseEvent( event: PointerEvent ): void {
    if ( !event.target || !anyPopupOpen() ) {
        return;
    }

    const target = event.target as HTMLElement;

    if ( utilityPopupOpen() && target.matches( '.popup__wrapper' ) ) {
        handleUtilityPopupClose();
        return;
    }

    if ( !target.matches( '.pagepop__scrollcontainer' ) && !target.matches( '.pagepopup__wrapper' ) ) {
        return;
    }

    handlePagePopupClose();
}

function handlePagePopupClose(): void {
    const pagePopupContainer = getPagePopupContainer();

    if ( pagePopupContainer.classList.contains( 'pagepopup__wrapper--loading' ) ) {
        return;
    }

    const closeButton = pagePopupContainer.querySelector( '.pagepopup__close' ) as HTMLElement;

    if ( !closeButton ) {
        throw new Error( 'failed to find page popup close button' );
    }

    const href = closeButton.getAttribute( 'href' );

    if ( href !== null && href.length > 0 ) {
        navigateTo( href );
        return;
    }

    const routeAction = closeButton.dataset.action;

    if ( !routeAction ) {
        throw new Error( `no router action found on the close button, html=${closeButton.outerHTML}` );
    }

    closeButton.dispatchEvent( new CustomEvent( 'twc:manual-navigation', {
        bubbles: true,
    } ) );
}

function handleUtilityPopupClose(): void {
    closeAllUtilityPopups();
}

function initPopupAltCloseMethods(): void {
    document.addEventListener( 'keydown', handleKeyboardEvent );
    document.addEventListener( 'click', handleMouseEvent );

    document.addEventListener( 'click', ( event: Event ) => {
        if ( !event.target || !( event.target instanceof HTMLElement ) ) {
            return;
        }
        if ( !event.target.matches( '[data-action="popup-close"]' ) ) {
            return;
        }
        if ( anyPopupOpen() ) {
            handleUtilityPopupClose();
            return;
        }

        const notificationPopup = document.querySelector( '.notification-popup' );

        if ( notificationPopup ) {
            notificationPopup.remove();
        }
    } );
}

export { initPopupAltCloseMethods, handlePagePopupClose };
