import Pristine from 'pristinejs';
import * as Sentry from '@sentry/browser';

import { onDirectoryEnquiryClose } from '../events/popup-close-directory-enquiry';
import { addRecaptchaField, getCaptchaAction, loadRecaptcha } from '../captcha/google';
import { resolveUsersVisitorInfo } from '../user/visitor-info';
import { getUser } from '../user/user';
import newValidator, { FormValidationError } from '../validation';
import { setupEmailSuggestionFromResult } from '../validation/email-suggest';
import triggerEvent, { ContactMethod, GAEventName, GenerateLeadEvent } from '../analytics/ga/events';
import { ErrorType, ErrorEvent } from '../analytics/ga/events';
import { getEngagedLabel } from '../analytics/ga/click-events';
import { getFormInfo } from '../forms/types';

export default function initDirectoryEnquiryForm( form: HTMLFormElement ) {
    let validator: Pristine | null = null;

    if ( form.dataset.initialised && form.dataset.initialised === 'true' ) {
        return;
    }

    const submitBtn = form.querySelector<HTMLButtonElement>( 'button[type=submit]' ) as HTMLButtonElement;

    function startLoading(): void {
        form.dataset.isProcessing = 'true';
        submitBtn.classList.add( 'btn--loading' );
    }

    function stopLoading(): void {
        form.dataset.isProcessing = 'false';
        submitBtn.classList.remove( 'btn--loading' );
    }

    form.dataset.initialised = 'true';

    form.addEventListener( 'submit', function( event: SubmitEvent ) {
        event.preventDefault();

        const form = event.target as HTMLFormElement;

        if ( !validator ) {
            validator = newValidator( form );
        }

        handleEnquiry( event, form );
    } );

    const handleEnquiry = async function( event: SubmitEvent, form: HTMLFormElement ) {
        if ( form.dataset.isProcessing && form.dataset.isProcessing === 'true' ) {
            return;
        }

        startLoading();

        const messageContainer = form.querySelector( '.messages' ) as HTMLElement;

        validator.reset();

        const valid = validator.validate();
        const errors: Array<FormValidationError> = validator.getErrors();

        if ( errors.length > 0 ) {
            errors.forEach( ( err: FormValidationError ) => {
                triggerEvent<ErrorEvent>( GAEventName.Error, {
                    'error_type': ErrorType.ErrForm,
                    'error_message': err.errors[ 0 ],
                } );
            } );
        }

        if ( !valid ) {
            stopLoading();
            return;
        }

        messageContainer.innerHTML = '';

        const sendRequest = async function sendRequest() {
            const formData = new FormData( form );

            formData.append( 'ipcountry', ( await getUser().visitorInfo() ).country );

            const url = new URL( window.location.origin + form.getAttribute( 'action' ) );

            window.http( url, {
                method: 'POST',
                body: formData,
            } )
                .then( function( response ) {
                    if ( !response.ok ) {
                        throw new Error( 'dirctory enquiry failed with status ' + response.status );
                    }

                    return response.json();
                } )
                .then( function( response ) {
                    stopLoading();

                    if ( response.didyoumean ) {
                        setupEmailSuggestionFromResult( {
                            emailSuggestion: response.didyoumean,
                            html: response.didyoumeanhtml,
                        }, form );
                        return;
                    }

                    if ( response.success ) {
                        document.body.insertAdjacentHTML( 'beforeend', response.data.response );

                        const closeBtn = document.querySelector( '[data-action="directory-enquiry-close"]' );

                        if ( closeBtn ) {
                            closeBtn.addEventListener( 'click', onDirectoryEnquiryClose );
                        }

                        window.twc.track( response.data.eventName, {
                            refId: response.data.refId,
                            dirs: JSON.parse( response.data.directory ),
                        } );

                        const formInfo = getFormInfo( form );

                        const generateLeadParameters: GenerateLeadEvent = {
                            'lead_id': response.data.redId,
                            'item_id': formData.get( 'outlet' ) as string,
                            'currency': window.currencyName,
                            'form_location': formInfo.location,
                            'contact_methods': ContactMethod.Email,
                        };

                        if ( event.submitter ) {
                            generateLeadParameters[ 'engaged_label' ] = getEngagedLabel( event.submitter );
                        }

                        triggerEvent<GenerateLeadEvent>( GAEventName.GenerateLead, generateLeadParameters );

                        window.dispatchEvent( new CustomEvent( 'twc:update-saved-status', {
                            bubbles: true,
                        } ) );
                    } else if ( response.errors && response.errors.form ) {
                        messageContainer.insertAdjacentHTML( 'beforeend', '<div class="alert alert-warning">' + response.errors.form + '</div>' );

                        window.scroll( 0, messageContainer.getBoundingClientRect().top + window.scrollY - 200 );

                        triggerEvent<ErrorEvent>( GAEventName.Error, {
                            'error_type': ErrorType.ErrServer,
                            'error_message': response.errors.form,
                        } );
                    }
                } ).catch( ( err ) => {
                    stopLoading();
                    Sentry.captureException( err );
                } );
        };

        async function resolveToken() {
            const token = await loadRecaptcha( getCaptchaAction( form ) );
            addRecaptchaField( form, token );
        }

        await Promise.all( [
            resolveUsersVisitorInfo(),
            resolveToken(),
        ] );

        await sendRequest();
    };
}
