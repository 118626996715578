/**
 * @param {String} url - the url string to parse
 * @returns URL - the parsed url
 */
function parseURL( url: string ): URL {
    if ( typeof url !== 'string' ) {
        throw new TypeError( 'url must be string, not ' + typeof url );
    }

    const isRelative = url.startsWith( '/' );
    let parsed;

    if ( isRelative ) {
        parsed = new URL( window.location.origin + url );
    } else {
        parsed = new URL( url );
    }

    return parsed;
}

export {
    parseURL,
};
