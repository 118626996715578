import triggerEvent, { GAEventName, GAEventParameters } from './events';

export function initGlobalAnalyticEventHandler(): void {
    document.addEventListener( 'click', async ( event: MouseEvent ): Promise<void> => {
        if ( !event.target || !( event.target instanceof HTMLElement ) ) {
            return;
        }

        const target = event.target;

        if ( !target.matches( '[data-ga-event]' ) ) {
            return;
        }

        const eventName = target.dataset.gaEvent;

        if ( !eventName ) {
            throw new Error( 'event name undefined' );
        }

        let eventParameters: GAEventParameters = {};

        if ( target.dataset.gaEventParameters ) {
            eventParameters = JSON.parse( target.dataset.gaEventParameters || '{}' ) as GAEventParameters;
        }

        await triggerEvent( eventName as GAEventName, eventParameters );
    } );
}
