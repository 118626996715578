import { NewContentLoadedEvent } from '../events/new-content-loaded';
import getIcon from '../svgs';
import { getLanguage } from '../user/device-info';
import { yieldToMainThread } from '../yield';

export const resetBtnTranslations: Record<string, Record<string, string>> = {
    en: {
        btnTitle: 'Clear Filters',
    },
    da: {
        btnTitle: 'Fjern filtre',
    },
    de: {
        btnTitle: 'Filter entfernen',
    },
    es: {
        btnTitle: 'Eliminar los filtros',
    },
    fi: {
        btnTitle: 'Poista suodattimet',
    },
    fr: {
        btnTitle: 'Supprimer les filtres',
    },
    it: {
        btnTitle: 'Togli i filtri',
    },
    nl: {
        btnTitle: 'Filters verwijderen',
    },
    no: {
        btnTitle: 'Fjern filtre',
    },
    pl: {
        btnTitle: 'Usuń filtry',
    },
    pt: {
        btnTitle: 'Elimine Filtros',
    },
    sv: {
        btnTitle: 'Ta bort filter',
    },
};

function resetableField(): void {
    const makeResetable = function( input: HTMLInputElement ) {
        if ( !input || input.dataset.resetInitialized ) {
            return;
        }

        input.dataset.resetInitialized = 'true';

        const wrap = document.createElement( 'div' );
        wrap.className = 'reseteable';

        let targetName = '';

        if ( input.name ) {
            targetName = input.name;
        }

        wrap.innerHTML = `<button type="button" class="reset-field" data-target-input="${targetName}" title="${resetBtnTranslations[ getLanguage() ].btnTitle}">${getIcon( 'closeX', 'grey-d2' )}</button>`;

        ( input.parentNode as HTMLElement ).insertBefore( wrap, input );

        wrap.appendChild( input );

        const resetBtn = wrap.querySelector( '.reset-field' ) as HTMLButtonElement;

        const toggleState = function() {
            if ( input.value && input.value.length > 0 ) {
                resetBtn.style.visibility = '';
            } else {
                resetBtn.style.visibility = 'hidden';
            }
        };

        resetBtn.addEventListener( 'click', async function() {
            input.value = '';
            input.dispatchEvent( new Event( 'change' ) );
            toggleState();
            input.focus();
            await yieldToMainThread();
        } );

        [ 'input', 'change' ].forEach( function( event: string ) {
            input.addEventListener( event, function() {
                toggleState();
            } );
        } );

        toggleState();
    };

    document.querySelectorAll<HTMLInputElement>( '[data-resetable]' ).forEach( function( el: HTMLInputElement ) {
        makeResetable( el );
    } );

    document.addEventListener( 'twc:newcontentloaded', function( event: CustomEvent<NewContentLoadedEvent> ) {
        if ( event.detail && event.detail.container ) {
            event.detail.container.querySelectorAll( '[data-resetable]' ).forEach( function( el: HTMLInputElement ) {
                makeResetable( el );
            } );
        }
    } );
}

export default resetableField;
