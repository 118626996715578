export const FormUpdateMailings: string = 'update_mailings';

export const FormUpdateFrequency: string = 'update_notifications';

type FormInfo = {
    name: string;
    location: string;
}

export function getFormInfo( form: HTMLFormElement ): FormInfo {
    return {
        name: form.getAttribute( 'name' ) || '',
        location: form.dataset.formLocation || 'body',
    };
}
