export function initFlashMsg(): void {
    if ( document.querySelector( '.flash' ) ) {
        document.addEventListener( 'click', ( event: Event ) => {
            if ( !event.target || !( event.target instanceof HTMLElement ) ) {
                return;
            }

            const btn = event.target.closest<HTMLButtonElement>( '.flash button' );

            if ( btn ) {
                ( btn.closest( '.flash' ) as HTMLElement ).remove();
            }
        } );
    }
}
