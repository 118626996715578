import requestIdleCallback from '../../shims/requestIdleCallback';
import { tryGetIconID } from '../../svgs';
import { parseURL } from '../../url/parse';
import triggerEvent, { GAEventName, GAEventParameters } from './events';

export interface ClickEvent extends GAEventParameters {
    // description of the text/icon of the clicked element
    'engaged_label': string;

    // simplified selector path of the clicked element
    'engaged_element': string;

    // parent container group name
    'engaged_group': string;

    // destination URL (href) of a link
    'engaged_target'?: string;
}

export function initGlobalAnalyticClickEvents(): void {
    document.addEventListener( 'click', async function( event: MouseEvent ): Promise<void> {
        if ( !event.target ) {
            return;
        }

        const isButton = event.target instanceof HTMLAnchorElement;
        const isLink = event.target instanceof HTMLButtonElement;

        let target: HTMLAnchorElement | HTMLButtonElement;

        if ( !isButton && !isLink ) {
            const parentButtonOrLink = ( event.target as HTMLElement ).closest( 'a,button' ) as HTMLButtonElement | HTMLAnchorElement | null;

            if ( !parentButtonOrLink ) {
                return;
            }

            target = parentButtonOrLink;
        } else {
            target = event.target;
        }

        let eventName = GAEventName.Click;

        if ( target.dataset.gaEventName ) {
            eventName = target.dataset.gaEventName as GAEventName;
        }

        requestIdleCallback( () => {
            const parameters: ClickEvent = {
                'engaged_label': getEngagedLabel( target ),
                'engaged_element': getElementIdentifier( target ),
                'engaged_group': 'not_grouped',
            };

            const containerName = getContainerName( target );

            if ( containerName ) {
                parameters[ 'engaged_group' ] = containerName;
            }

            const engagedTarget = getEngagedTarget( target );

            if ( engagedTarget ) {
                parameters[ 'engaged_target' ] = engagedTarget;
            }

            triggerEvent<ClickEvent>( eventName, parameters );
        } );
    } );
}

function getContainerName( element: HTMLElement ): string {
    const container = element.closest( '[data-ga-id]' ) as HTMLElement | null;

    if ( container && container.dataset.gaId ) {
        return `[data-ga-id="${container.dataset.gaId}"]`;
    }

    let parent: HTMLElement | null = element.parentElement;

    while ( parent !== null ) {
        if ( parent.id ) {
            return parent.nodeName.toLowerCase() + '#' + parent.id;
        }

        parent = parent.parentElement;
    }

    return '';
}

const cssClassPatternsToIgnore: Array<RegExp> = [
    /disable-child-touch/,
    /^flex-/,
    /^d-/,
    /^bg-/,
    /^position-/,
    /^(p|pt|pl|pb|pr)-/,
    /^(m|mt-ml|mb|mr)-/,
    /^text-/,
    /^h-/,
    /^w-/,
    /^color-/,
    /^(bold|normal|italic)$/,
    /^overflow-/,
];

function getElementClassPath( element: HTMLElement ): string {
    return element.className.split( ' ' ).filter( ( cssClass: string ) => {
        return !cssClassPatternsToIgnore.some( ( exp ) => exp.test( cssClass ) );
    } ).join( '.' );
}

function getUsableIdentifier( element: HTMLElement, requireDetail: boolean ): string {
    const nodeName = element.nodeName.toLowerCase();

    let base = nodeName;

    const btnType = element.getAttribute( 'type' ) || '';

    if ( btnType && btnType.toLowerCase() === 'submit' ) {
        base += `[type="${element.getAttribute( 'type' )?.toLowerCase()}"]`;
    }

    if ( element.dataset.gaId ) {
        return `${base}[data-ga-id="${element.dataset.gaId}"]`;
    }

    if ( element.id ) {
        return `${base}#${element.id}`;
    }

    if ( element.className.length > 0 ) {
        const classes = getElementClassPath( element );

        if ( classes.length > 0 ) {
            return base + '.' + classes;
        }

        return base;
    }

    if ( requireDetail ) {
        return nodeName;
    }

    return base;
}

function getElementIdentifier( element: HTMLElement ): string {
    function prependContainer( path: string ): string {
        const container = getContainerName( element );

        if ( !container ) {
            return path;
        }

        return `${container} ${path}`;
    }

    const elementID = getUsableIdentifier( element, true );

    if ( elementID.length > 0 && elementID !== element.nodeName.toLowerCase() ) {
        return prependContainer( elementID );
    }

    function buildPath( e: HTMLElement, p?: HTMLElement ): string {
        const eID = getUsableIdentifier( e, true );

        if ( eID.length > 0 && eID !== e.nodeName.toLowerCase() ) {
            return eID;
        }

        if ( !e.parentElement ) {
            throw new Error( 'parentElement undefined' );
        }

        if ( p ) {
            const pID = getUsableIdentifier( p, false );

            if ( pID.length > 0 ) {
                return pID + ' ' + getUsableIdentifier( e, false );
            }
        }

        return buildPath( e, e.parentElement );
    }

    return prependContainer( buildPath( element ) );
}

function getEngagedTarget( btn: HTMLButtonElement | HTMLAnchorElement ): string {
    function sanitiseURL( raw: string ) {
        if ( /^(http|https):\/\//.test( raw ) ) {
            return raw;
        }

        if ( /^(tel|mailto|):/.test( raw ) ) {
            return raw;
        }

        const u = parseURL( raw );

        return u.pathname + u.search;
    }

    if ( btn.hasAttribute( 'href' ) ) {
        return sanitiseURL( btn.getAttribute( 'href' ) as string );
    }

    if ( btn.hasAttribute( 'data-href' ) ) {
        return sanitiseURL( btn.getAttribute( 'data-href' ) as string );
    }

    return '';
}

export function getEngagedLabel( btn: HTMLElement ): string {
    const innerTxt = btn.innerText.trim();

    let titleSuffix = '';

    if ( innerTxt.length === 0 && btn.title ) {
        titleSuffix = `,title=${btn.title}`;
    }

    if ( innerTxt.length === 0 ) {
        const icon = btn.querySelector( 'svg' ) as SVGAElement | null;

        if ( icon ) {
            const iconID = tryGetIconID( icon );

            if ( iconID.length === 0 ) {
                return 'icon=unknwon' + titleSuffix;
            }

            return `icon=${iconID}` + titleSuffix;
        }

        const img = btn.querySelector( 'img' ) as HTMLImageElement | null;

        if ( img ) {
            const src = new URL( img.src );
            const pathParts = src.pathname.split( '/' );

            return `img=${pathParts[ pathParts.length - 1 ]}` + titleSuffix;
        }

        return 'no-text-no-icon' + titleSuffix;
    }

    return innerTxt;
}
