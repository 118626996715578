import { setUser } from '@sentry/browser';
import { HttpMiddleware, RequestObj } from '../http/models';
import { getUser } from '../user/user';
import { getEngagedLabel } from './ga/click-events';
import { GA4Event, GAEventName, GenerateLeadEvent, pushAnalyticsEvent, pushAnalyticsEvents } from './ga/events';

type UserData = {
    id: number;
    email: string;
    emailHash?: string;
    verified: string;
    phone?: string;
    lastProvidedPhone?: string;
}

export const AnalyticsHeader: string = 'X-Google-Analytics-Events';

export const UserDataHeader: string = 'X-Twc-Userdata';

export default function( next: HttpMiddleware ) {
    return async function( uri: string, request: RequestObj ): Promise<Response> {
        const response: Response = await next( uri, request );

        const eventsToPush = response.headers.get( AnalyticsHeader );

        if ( eventsToPush ) {
            pushAnalyticsEvents( JSON.parse( eventsToPush ) as Array<GA4Event> || [] );
        }

        const userDataJSON = response.headers.get( UserDataHeader );

        if ( userDataJSON ) {
            const userData = JSON.parse( userDataJSON ) as UserData;

            if ( userData.emailHash && userData.emailHash.length > 0 ) {
                window.userID = userData.emailHash;
            }

            if ( !window.dataLayer.some( ( x ) => !!x[ 'user_id' ] ) && userData.emailHash ) {
                pushAnalyticsEvent( {
                    'user_id': userData.emailHash,
                } );
            }

            if ( !window.dataLayer.some( ( x ) => !!x[ 'user_email' ] ) && userData.emailHash ) {
                pushAnalyticsEvent( {
                    'user_email': userData.emailHash,
                } );
            }

            window.twc.identify( userData );

            if ( userData.phone || userData.lastProvidedPhone ) {
                const user = getUser();

                user.phoneOne = userData.phone || user.phoneOne;
                user.phoneTwo = userData.lastProvidedPhone || user.phoneTwo;

                setUser( user );
            }
        }

        return response;
    };
}

export const TemplateAnalyticsHeader: string = 'X-Google-Analytics-Template-Events';

type TemplatedEventContext = {
    form: HTMLFormElement;
    event: SubmitEvent;
}

export function fillTemplatedEventsFromFormSubmission( templatedEvents: Array<GA4Event>, context: TemplatedEventContext ): Array<GA4Event> {
    let events: Array<GA4Event> = [];

    events = templatedEvents
        .filter( ( event: GA4Event ) => event.event === GAEventName.GenerateLead )
        .map( ( event: GA4Event<GenerateLeadEvent> ) => {
            if ( context.event.submitter ) {
                event.parameters[ 'engaged_label' ] = getEngagedLabel( context.event.submitter );
            }

            return event;
        } );

    return events;
}
