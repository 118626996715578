import Router from '../routing/router';
import AppStorage from '../storage/app-storage';
import popupHandler from '../popups/handler';
import onPopupOpen from '../events/popup-open';
import onNewContentLoaded from '../events/new-content-loaded';
import onPageShow from '../events/pageshow';
import initAdManager from '../adverts';
import initSideNavigation from '../navigation/side-nav';
import accountControls from '../user/account-widgets';
import initSuggestLanguagePopup from '../suggestlanguage';
import saveFromElement from '../user/save-from-element';
import initCountryCodes from '../user/telephone';
import exitIntent from '../user/exit-intent';
import requestIdleCallback from '../shims/requestIdleCallback';
import initSelectElements from '../selects/global';
import initAlertPopups from '../alert-popups';
import initSentry from '../logging/sentry';
import initCopyToClipboardButtons from '../clipboard/buttons';
import initPopupOpener from '../popups/opener';
import { initCollapsingElements } from '../collapse';
import { initDropdowns } from '../dropdown';
import { storeSessionStartTime } from '../session/time';
import { onUpdateSavedStatus, updateSavedCounter } from '../user/saved';
import { initPopupAltCloseMethods } from '../popups/close-handler';
import { onPopupClose, onPopupCloseAfter } from '../events/popup-close';
import { setLastSearchResultPageCookie } from '../cookies';
import { getFetchAPI } from '../http';
import { initGlobalErrorLogger } from '../logging/errors';
import { initGoogleAnalyticHandlers } from '../analytics/ga';
import { initGenericForms } from '../simpleform';
import { initFlashMsg } from '../flash';
import { initGradientLoader } from '../loadingindicator/gradient';
import { updateAlertsStatus } from '../user/alerts';
import { initTWCGlobalClickEvents } from '../analytics/twc/clicks-events';

class GlobalContext {
    constructor() {
        initSentry();

        initGlobalErrorLogger();

        window.http = getFetchAPI();

        window.router = new Router();

        window.appStorage = new AppStorage();

        storeSessionStartTime();

        this._registerGlobalEvents();

        this._initialiseGlobalComponents();
    }

    _registerGlobalEvents(): void {
        window.addEventListener( 'pageshow', onPageShow );

        window.addEventListener( 'twc:newcontentloaded', onNewContentLoaded );

        window.addEventListener( 'twc:popup:open', onPopupOpen );

        window.addEventListener( 'twc:popup:close', onPopupClose );

        window.addEventListener( 'twc:popup:close:after', onPopupCloseAfter );

        window.addEventListener( 'twc:navigation', popupHandler );

        window.addEventListener( 'twc:update-saved-status', onUpdateSavedStatus );

        window.addEventListener( 'twc:update-saved-counter', updateSavedCounter );
    }

    _initialiseGlobalComponents(): void {
        initSideNavigation();

        initAdManager();

        accountControls();

        initSuggestLanguagePopup();

        saveFromElement();

        initCountryCodes();

        initPopupAltCloseMethods();

        exitIntent();

        initAlertPopups();

        initGoogleAnalyticHandlers();

        initTWCGlobalClickEvents();

        initCopyToClipboardButtons();

        initPopupOpener();

        initGenericForms( document, true );

        initFlashMsg();

        initSelectElements();

        initCollapsingElements();

        initDropdowns();

        initGradientLoader();

        requestIdleCallback( updateAlertsStatus );

        document.dispatchEvent( new CustomEvent( 'twc:update-saved-status', {
            bubbles: true,
        } ) );

        if ( window.setSearchResultPageCookie && window.setSearchResultPageCookie === true ) {
            setLastSearchResultPageCookie();
        }
    }
}

export default GlobalContext;
