import Awesomplete from 'awesomplete';
import { yieldToMainThread } from '../yield';

const CustomAwesomplete = Awesomplete;

CustomAwesomplete.prototype.open = async function(): Promise<void> {
    this.ul.classList.add( 'visible' );
    this.isOpened = true;
};

CustomAwesomplete.prototype.close = async function(): Promise<void> {
    if ( !this.opened ) {
        return;
    }

    await yieldToMainThread();

    this.ul.classList.remove( 'visible' );
    this.isOpened = false;
    this.index = -1;
};

export default CustomAwesomplete;
