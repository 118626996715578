import { sanitiseHrefTel } from '../../tel/sanitise';
import { getUser } from '../../user/user';
import { triggerTWCEvent, TWCCallEvent, TWCEvent, TWCEventName, TWCEventParameters } from './events';

type Matcher = {
    match: ( t: EventTarget ) => boolean;
    event: ( element: Element ) => Promise<TWCEvent<TWCEventParameters>>;
}

const eventHandlerMapping: Array<Matcher> = [
    {
        match: ( t: EventTarget ) => {
            return ( t instanceof HTMLAnchorElement && t.href.startsWith( 'tel:' ) );
        },
        event: async ( element: HTMLElement ): Promise<TWCEvent<TWCCallEvent>> => {
            const user = getUser();

            const origins: Array<string> = [];

            if ( user.phoneOne ) {
                origins.push( user.phoneOne );
            }

            if ( user.phoneTwo && user.phoneTwo !== user.phoneOne ) {
                origins.push( user.phoneTwo );
            }

            const elementWithID = element.closest<HTMLElement>( '[data-property-id]' );

            if ( !elementWithID ) {
                throw new Error( 'no parent data-property-id element found' );
            }

            return {
                eventName: TWCEventName.Call,
                [ TWCEventName.Call ]: {
                    destination: sanitiseHrefTel( element.getAttribute( 'href' ) || '' ),
                    originCountry: ( await getUser().visitorInfo() ).country,
                    origins: origins,
                    itemID: parseInt( elementWithID.dataset.propertyId || '0' ),
                },
            } as TWCEvent<TWCCallEvent>;
        },
    },
];

export function initTWCGlobalClickEvents(): void {
    document.addEventListener( 'click', ( event: Event ) => {
        if ( !event.target ) {
            return;
        }

        let element: EventTarget | null = event.target;

        const isClickable = ( event.target instanceof HTMLButtonElement || event.target instanceof HTMLAnchorElement );

        if ( !isClickable && event.target instanceof Element ) {
            element = ( event.target.closest<HTMLAnchorElement | HTMLButtonElement>( 'a,button' ) );
        }

        if ( !element ) {
            return;
        }

        eventHandlerMapping
            .filter( ( m: Matcher ) => {
                return m.match( element as EventTarget );
            } )
            .forEach( async ( m: Matcher ) => {
                const e = await m.event( element as Element );
                triggerTWCEvent( e.eventName, e[ e.eventName ] );
            } );
    }, { passive: true } );
}
