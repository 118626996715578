import { NewContentLoadedEvent } from '../events/new-content-loaded';

export default function initCopyToClipboardButtons(): void {
    if ( !window.navigator || !window.navigator.clipboard ) {
        return;
    }

    function init(): void {
        document.querySelectorAll<HTMLButtonElement | HTMLAnchorElement>( '[data-copy-to-clipboard]' ).forEach( ( btn: HTMLButtonElement ) => {
            if ( !btn.dataset.copyToClipboard || btn.dataset.copyToClipboardInitialised === 'true' ) {
                return;
            }

            btn.dataset.copyToClipboardInitialised = 'true';

            btn.addEventListener( 'click', async ( event: PointerEvent ) => {
                if ( btn.nodeName === 'A' ) {
                    event.preventDefault();
                }

                await window.navigator.clipboard.writeText( btn.dataset.copyToClipboard as string );

                btn.classList.add( 'copied' );

                const copiedText = btn.dataset.copyToClipboardCopied;

                let tooltipRemove;

                if ( copiedText && !btn.querySelector( '.copytoclipboard__tooltip' ) ) {
                    const tooltip = document.createElement( 'span' );
                    tooltip.className = 'copytoclipboard__tooltip copytoclipboard__tooltip--visible';
                    tooltip.innerHTML = copiedText;

                    btn.appendChild( tooltip );

                    tooltipRemove = () => {
                        tooltip.classList.remove( 'copytoclipboard__tooltip--visible' );

                        window.setTimeout( () => {
                            tooltip.remove();
                        }, 500 );
                    };
                }

                window.setTimeout( () => {
                    if ( tooltipRemove ) {
                        tooltipRemove();
                    }

                    btn.classList.remove( 'copied' );
                }, 2000 );
            } );
        } );
    }

    window.addEventListener( 'twc:newcontentloaded', ( event: CustomEvent<NewContentLoadedEvent> ) => init() );

    init();
}
