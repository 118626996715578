import { NewContentLoadedEvent } from '../events/new-content-loaded';

export default function initSelectElements(): void {
    const updateClass = function updateClass( select: HTMLSelectElement ) {
        if ( select.value ) {
            select.classList.add( 'has-value' );

            if ( !select.dataset.setSelected || select.dataset.setSelected === 'true' ) {
                select.querySelectorAll( 'option' ).forEach( ( option: HTMLOptionElement ) => {
                    if ( option.value === select.value ) {
                        option.setAttribute( 'selected', 'true' );
                    } else {
                        option.removeAttribute( 'selected' );
                    }
                } );
            }
        } else {
            select.classList.remove( 'has-value' );

            if ( !select.dataset.setSelected || select.dataset.setSelected === 'true' ) {
                select.querySelectorAll( 'option' ).forEach( ( option: HTMLOptionElement ) => option.removeAttribute( 'selected' ) );
            }
        }
    };

    document.querySelectorAll<HTMLSelectElement>( 'select' ).forEach( ( select: HTMLSelectElement ) => {
        updateClass( select );
        select.addEventListener( 'change', () => updateClass( select ) );
    } );

    document.addEventListener( 'twc:newcontentloaded', ( event: CustomEvent<NewContentLoadedEvent> ) => {
        if ( event.detail && event.detail.container ) {
            event.detail.container.querySelectorAll( 'select' ).forEach( ( select: HTMLSelectElement ) => {
                updateClass( select );
                select.addEventListener( 'change', () => updateClass( select ) );
            } );
        }
    } );
}
