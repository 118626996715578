import { navigateTo } from '../http/redirect';
import { setLastSearchResultPageCookie } from '../cookies';
import { getPagePopupContainer } from '../popups/page-popup';
import Logger from '../logging/logger';
import requestIdleCallback from '../shims/requestIdleCallback';
import { yieldToMainThread } from '../yield';
import { triggerPageView } from '../analytics/ga/events';

async function onPopupClose( event ) {
    const detail = event.detail || {};

    Logger.info( 'Event Triggered: onPopupClose', detail );

    const navigateOnClose = ( detail.previous && detail.previous.navigateOnClose ) || false;

    if ( navigateOnClose && detail.isDirect && !detail.isRoutedPopup ) {
        navigateTo( detail.navigateOnClose );
        return;
    }

    const needRedirecting = ( detail.previous && detail.previous.isDirect && detail.previous.isDirect ) || false;

    if ( ( !detail.isRoutedPopup && needRedirecting ) || detail.noFollow ) {
        navigateTo( detail.url );
        return;
    }

    if ( !document.body.classList.contains( 'page-popup-open' ) ) {
        return;
    }

    const popupWrapper = getPagePopupContainer();

    popupWrapper.classList.remove( 'pagepopup__wrapper--open' );

    document.body.classList.remove( 'page-popup-open', 'popup-open-search' );

    await yieldToMainThread();

    if ( detail.previous && detail.previous.events && detail.previous.events.close.length > 0 ) {
        detail.previous.events.close.forEach( function( name ) {
            document.dispatchEvent( new CustomEvent( name, {
                detail: detail,
                bubbles: true,
            } ) );
        } );
    }

    const popupContent = popupWrapper.querySelector( '.pagepopup__content' );

    const popupSliders = Array.from( popupContent.querySelectorAll( '.twc__property--slider-initialised' ) )
        .filter( ( element ) => !!element.slider )
        .map( ( element ) => element );

    requestIdleCallback( () => {
        popupSliders.forEach( ( element ) => {
            element.slider.destroy();
            delete element.slider;
        } );
    } );

    window.requestAnimationFrame( () => {
        const elementToScroll = document.scrollingElement;

        if ( !elementToScroll ) {
            throw new Error( 'no scrolling element return by document.scrollingElement' );
        }

        elementToScroll.scrollTop = detail.scrollPosition;
    } );

    popupContent.innerHTML = '';

    const enquiryconfirmation = document.querySelector( '.notification-popup' );
    if ( enquiryconfirmation ) {
        enquiryconfirmation.remove();
    }
}

function onPopupCloseAfter() {
    Logger.info( 'Event Triggered: onPopupCloseAfter' );

    triggerPageView();

    setLastSearchResultPageCookie();
}

export {
    onPopupClose,
    onPopupCloseAfter,
};
