import Logger from '../../logging/logger';
import { getLanguage } from '../../user/device-info';
import { getCurrentPagePath } from '../../url';
import { parseEscapedJSONString } from '../../json/parse';
import { GAParameterContentCategory, GAParameterContentGroup, GAParameterContentSection, ValidGAParameters } from './parameters';
import { yieldToMainThread } from '../../yield';

export enum GAEventName {
    PageView        = 'pageview',
    Error           = 'error',
    Share           = 'share',
    Click           = 'click',
    GenerateLead    = 'generate_lead',
    ContentRating   = 'content_rating',

    ItemSave            = 'item_save',
    ItemDiscard         = 'item_discard',

    UserLogin           = 'user_login',
    UserRegistration    = 'user_registration',

    MailshotSubscribe   = 'email_subscribe',
    MailshotUnSubscribe = 'email_unsubscribe',
    MailshotReSubscribe = 'email_resubscribe',

    EcommercePurchase       = 'purchase',
    EcommerceViewItem       = 'view_item',
    EcommerceViewItemList   = 'view_item_list',
    EcommerceAddToWishlist  = 'add_to_wishlist',
    EcommerceAddToCart      = 'add_to_cart',
    EcommerceRemoveFromCart = 'remove_from_cart',
    EcommerceViewCart       = 'view_cart',
}

type GA4Parameter = string | number | null | undefined;

export type GAEventParameters = Record<string, GA4Parameter>;

export interface GA4Event<T extends GAEventParameters = Record<string, GA4Parameter>> {
    event: GAEventName;
    parameters: T;
}

export type PageView = {
    event: string;
    page: string;
    contentGroup: string;
    content_group: string;
    content_category: string;
    content_section?: string;
}

export function triggerPageView( overrides: { page?: string, contentGroup?: string } = {} ): PageView {
    const contentParams = getContentParameters();

    let pageView: PageView = {
        event: GAEventName.PageView,
        page: getCurrentPagePath(),
        contentGroup: contentParams.contentGroup,
        [ GAParameterContentGroup ]: contentParams.contentGroup,
        [ GAParameterContentCategory ]: contentParams.contentCategory,
    };

    if ( contentParams.contentSection ) {
        pageView[ GAParameterContentSection ] = contentParams.contentSection;
    }

    if ( overrides && Object.keys( overrides ).length > 0 ) {
        pageView = {
            ...pageView,
            ...overrides,
        };
    }

    pushAnalyticsEvent( pageView );

    return pageView;
}

export default async function triggerEvent<T extends GAEventParameters>( eventName: GAEventName, parameters: T ): Promise<GA4Event> {
    let eventParams: GAEventParameters = {
        lang: getLanguage(),
    };

    if ( window.baseAnalyticEventParameters ) {
        eventParams = {
            ...eventParams,
            ...window.baseAnalyticEventParameters,
            ...parameters,
        };
    }

    const elementWithOverrides = document.querySelector<HTMLElement>( '[data-base-analytic-event-parameters-override]' );

    if ( elementWithOverrides && elementWithOverrides.dataset.baseAnalyticEventParametersOverride ) {
        const elementOverrides = parseEscapedJSONString( elementWithOverrides.dataset.baseAnalyticEventParametersOverride || '{}' ) as GAEventParameters;

        eventParams = {
            ...eventParams,
            ...elementOverrides,
        };
    }

    eventParams = {
        ...eventParams,
        ...parameters,
    };

    eventParams = nullifyPreviouslySetParameters( eventParams );

    const event: GA4Event = {
        event: eventName,
        parameters: eventParams,
    };

    Object.keys( eventParams ).forEach( ( key: string ) => {
        if ( !ValidGAParameters.includes( key ) ) {
            throw new Error( `unknown event parameter "${key}"` );
        }
    } );

    await yieldToMainThread();

    pushAnalyticsEvent( event );

    await yieldToMainThread();

    return event;
}

export function pushAnalyticsEvents( events: Array<any> ): void {
    events.forEach( ( event ) => pushAnalyticsEvent( event ) );
}

export function pushAnalyticsEvent( event: any ): void {
    if ( !event.contentGroup ) {
        event.contentGroup = getContentGroup();
    }

    if ( event.parameters && !event.parameters[ 'content_group' ] ) {
        event.parameters[ 'content_group' ] = getContentGroup();
    }

    if ( event.parameters && !event.parameters[ 'content_section' ] ) {
        event.parameters[ 'content_section' ] = getContentSection();
    }

    if ( event.parameters && !event.parameters[ 'content_category' ] ) {
        event.parameters[ 'content_category' ] = getContentCategory();
    }

    if ( !event[ 'user_id' ] && window.userID ) {
        event[ 'user_id' ] = window.userID;
    }

    Logger.info( `Analytics event: ${event.event}`, event );

    window.dataLayerPush( event );
}

function nullifyPreviouslySetParameters( parameters: GAEventParameters ): GAEventParameters {
    let previouslySentParameters: GAEventParameters = {};

    window.dataLayer.map( ( obj ) => {
        if ( !obj[ 'parameters' ] ) {
            return {};
        }
        return obj[ 'parameters' ];
    } ).forEach( ( parameterObject: GAEventParameters ) => {
        previouslySentParameters = {
            ...previouslySentParameters,
            ...parameterObject,
        };
    } );

    Object.keys( previouslySentParameters ).forEach( ( key: string ) => {
        previouslySentParameters[ key ] = null;
    } );

    return {
        ...previouslySentParameters,
        ...parameters,
    };
}

export enum ErrorType {
    ErrLogin = 'error_login',
    ErrServer = 'error_server',
    ErrForm = 'error_form',
}

export enum SaveAction {
    Save = 'save',
    Unsave = 'unsave',
}

export interface SaveEvent extends GAEventParameters {
    action: SaveAction;
    item_id?: string;
	item_name?: string;
	item_brand?: string;
	item_variant?: string;
	item_category?: string;
	item_category2?: string;
	item_category3?: string;
	item_category4?: string;
	item_category5?: string;
}

export enum DiscardAction {
    Discard = 'discard',
    Recover = 'recover',
}

export interface DiscardEvent extends GAEventParameters {
    action: DiscardAction;
    item_id?: string;
	item_name?: string;
	item_brand?: string;
	item_variant?: string;
	item_category?: string;
	item_category2?: string;
	item_category3?: string;
	item_category4?: string;
	item_category5?: string;
}

export interface ErrorEvent extends GAEventParameters {
    'error_type': ErrorType;
    'error_location'?: string;
    'error_message'?: string;
}

export interface ShareEvent extends GAEventParameters {
    'share_method': string;
}

export interface GenerateLeadEvent extends GAEventParameters {
    'lead_id': string;
    'item_id'?: string;
	'item_name'?: string;
    'item_category'?: string;
	'item_category2'?: string;
	'item_category3'?: string;
	'item_category4'?: string;
	'item_category5'?: string;
	'item_variant'?: string;
	'item_brand'?: string;
    'currency': string;
    'form_location': string;
    'engaged_label'?: string;
    'contact_methods': string;
}

export enum ContactMethod {
    Email       = 'email',
    WhatsApp    = 'whatsapp',
}

export interface MailshotSubscribeEvent extends GAEventParameters {
    'email_types': string;
    'email_frequency'?: string;
    'form_type': string;
    'form_location': string;
    'trigger_block_group'?: string;
    'trigger_timeout'?: string;
}

export interface MailshotUnSubscribeEvent extends GAEventParameters {
    'email_types': string;
    'email_frequency'?: string;
    'email_unsubscribe_reason': string;
    'form_type': string;
    'form_location': string;
}

export interface MailshotReSubscribeEvent extends GAEventParameters {
    'email_types': string;
    'email_frequency'?: string;
    'form_type': string;
    'form_location': string;
}

function getParameterOverrides(): GAEventParameters {
    const elementWithOverrides = document.querySelector( '[data-base-analytic-event-parameters-override]' ) as HTMLElement | null;

    if ( !elementWithOverrides || !elementWithOverrides.dataset.baseAnalyticEventParametersOverride ) {
        return {};
    }

    return parseEscapedJSONString( elementWithOverrides.dataset.baseAnalyticEventParametersOverride ) as GAEventParameters;
}

type ContentParameters = {
    contentGroup: string;
    contentCategory: string;
    contentSection?: string;
}

function getContentParameters(): ContentParameters {
    return {
        contentGroup: getContentGroup(),
        contentCategory: getContentCategory(),
        contentSection: getContentSection(),
    };
}

function getContentSection(): string {
    const overrides = getParameterOverrides();

    if ( overrides[ 'content_section' ] ) {
        return overrides[ 'content_section' ] as string;
    }

    if ( window.baseAnalyticEventParameters && window.baseAnalyticEventParameters[ 'content_section' ] ) {
        return window.baseAnalyticEventParameters[ 'content_section' ] as string;
    }

    return '';
}

function getContentGroup(): string {
    const overrides = getParameterOverrides();

    if ( overrides[ 'content_group' ] ) {
        return overrides[ 'content_group' ] as string;
    }

    if ( window.baseAnalyticEventParameters && window.baseAnalyticEventParameters[ 'content_group' ] ) {
        return window.baseAnalyticEventParameters[ 'content_group' ] as string;
    }

    return '';
}

function getContentCategory(): string {
    const overrides = getParameterOverrides();

    if ( overrides[ 'content_category' ] ) {
        return overrides[ 'content_category' ] as string;
    }

    if ( window.baseAnalyticEventParameters && window.baseAnalyticEventParameters[ 'content_category' ] ) {
        return window.baseAnalyticEventParameters[ 'content_category' ] as string;
    }

    return '';
}
