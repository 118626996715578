import { yieldToMainThread } from '../../yield';
import { GAEventName } from './events';
import { pushAnalyticsEvent } from './events';

export type EcommerceEvent = {
	event: GAEventName;
	ecommerce: any;
	contentGroup?: string;
}

export type EcommerceItem = {
	item_id?: string;
	item_name?: string;
	index?: number;
	item_brand?: string;
	item_category?: string;
	item_category2?: string;
	item_category3?: string;
	item_category4?: string;
	item_category5?: string;
	item_list_id?: string;
	item_list_name?: string;
	item_variant?: string;
	location_id?: string;
	price?: number;
	quantity?: number;
}

export type AddToCartEvent = {
	event: GAEventName.EcommerceAddToCart;
	ecommerce: {
		currency: string;
		value: number;
		items: Array<EcommerceItem>;
	}
}

export type RemoveToCartEvent = {
	event: GAEventName.EcommerceRemoveFromCart;
	ecommerce: {
		currency: string;
		value: number;
		items: Array<EcommerceItem>;
	}
}

export type AddToWishlistEvent = {
	event: GAEventName.EcommerceAddToWishlist;
	ecommerce: {
		currency: string;
		value: number;
		items: Array<EcommerceItem>;
	}
}

const validEcommerceEvents: Array<string> = [
    GAEventName.EcommerceAddToCart,
    GAEventName.EcommerceAddToWishlist,
    GAEventName.EcommercePurchase,
    GAEventName.EcommerceRemoveFromCart,
    GAEventName.EcommerceViewCart,
    GAEventName.EcommerceViewItem,
    GAEventName.EcommerceViewItemList,
];

export function clearEcommerceContext(): void {
    window.dataLayerPush( { ecommerce: null } );
}

export async function pushEcommerceEvent( event: EcommerceEvent ): Promise<void> {
    if ( !validEcommerceEvents.includes( event.event ) ) {
        throw new Error( `unknown ecommerce event "${event.event}"` );
    }

    await yieldToMainThread();

    clearEcommerceContext();

    pushAnalyticsEvent( event );
}
