import requestIdleCallback from '../shims/requestIdleCallback';
import { yieldToMainThread } from '../yield';

class Dropdown {
    _open = false;

    constructor() {
        document.addEventListener( 'click', async ( event: Event ) => {
            if ( this._open && event.target && ( event.target instanceof HTMLElement ) && !event.target.matches( '[data-toggle="dropdown"]' ) ) {
                document.querySelectorAll<HTMLElement>( '.dropdown.open' ).forEach( ( dropdown ) => dropdown.classList.remove( 'open' ) );
                this._open = false;
            } else {
                await this._onClick( event );
            }
        } );

        document.addEventListener( 'twc:dropdown:close', ( event: CustomEvent ) => {
            this._handleClose( event.target as HTMLButtonElement, ( event.target as HTMLElement ).parentElement as HTMLElement );
        } );

        const options = document.querySelectorAll<HTMLButtonElement>( '.dropdown-menu[data-select=true][data-select-form] button' );

        for ( let i = 0; i < options.length; i++ ) {
            options[ i ].addEventListener( 'click', function( event ) {
                const option = event.target as HTMLButtonElement;

                if ( option.classList.contains( 'active' ) ) {
                    return;
                }

                const menu = option.closest( '.dropdown-menu' ) as HTMLElement;

                const targetForm = document.querySelector<HTMLFormElement>( menu.dataset.selectForm as string );

                if ( !targetForm || !targetForm.dataset.autoSubmit || targetForm.dataset.autoSubmit === 'false' ) {
                    menu.querySelectorAll( 'button[role=menuitem]' ).forEach( ( s: HTMLButtonElement ) => {
                        if ( s.classList.contains( 'active' ) ) {
                            s.classList.remove( 'active' );
                        }
                    } );
                    option.classList.add( 'active' );
                }

                if ( !targetForm ) {
                    return;
                }

                const existingInput = targetForm.querySelector<HTMLInputElement>( `input[name="${menu.dataset.selectInputName}"]` );

                if ( existingInput ) {
                    existingInput.value = option.dataset.value || '';
                    existingInput.dispatchEvent( new Event( 'change', { bubbles: true } ) );
                } else {
                    const input = document.createElement( 'input' );
                    input.type = 'hidden';
                    input.name = menu.dataset.selectInputName || '';
                    input.value = option.dataset.value || '';
                    input.dispatchEvent( new Event( 'change', { bubbles: true } ) );
                    targetForm.appendChild( input );
                }
            } );
        }
    }

    async _onClick( event: Event ): Promise<void> {
        if ( !event.target || !( event.target instanceof HTMLElement ) || !event.target.parentElement ) {
            return;
        }

        const btn = event.target as HTMLButtonElement;
        const parent = event.target.parentElement as HTMLElement;

        const isOpen = parent.classList.contains( 'open' );
        const parentMatches = parent.classList.contains( 'dropdown' );

        if ( !parentMatches ) {
            return;
        }

        if ( isOpen ) {
            await this._handleClose( btn, parent );
        }

        if ( !isOpen ) {
            await this._handleOpen( btn, parent );
        }
    }

    async _handleOpen( btn: HTMLButtonElement, parent: HTMLElement ): Promise<void> {
        parent.classList.add( 'open' );

        await yieldToMainThread();

        btn.setAttribute( 'aria-expanded', 'true' );

        this._open = true;

        requestIdleCallback( () => {
            closeExpandedDropdowns( btn );
        } );
    }

    async _handleClose( btn: HTMLButtonElement, parent: HTMLElement ): Promise<void> {
        parent.classList.remove( 'open' );

        await yieldToMainThread();

        btn.setAttribute( 'aria-expanded', 'false' );

        btn.dispatchEvent( new CustomEvent( 'twc:dropdown:closed', {
            bubbles: true,
        } ) );

        this._open = false;
    }
}

export function closeExpandedDropdowns( btn?: HTMLButtonElement ): void {
    document.querySelectorAll<HTMLElement>( '[data-toggle="dropdown"][aria-expanded="true"]' ).forEach( ( element: HTMLElement ) => {
        if ( btn && element.isEqualNode( btn ) ) {
            return;
        }

        element.dispatchEvent( new CustomEvent( 'twc:dropdown:close', {
            bubbles: true,
        } ) );
    } );
}

export function initDropdowns(): void {
    new Dropdown();
}
