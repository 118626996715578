import { triggerPageView } from '../analytics/ga/events';

function onPageShow( event: PageTransitionEvent ): void {
    triggerPageView();

    if ( event.persisted ) {
        /**
         * Update form select / dropdown elements which have a differing value and correctly selected option
         * this is caused by a page being recovered from the Back-Forward cache
         */
        document.querySelectorAll<HTMLSelectElement>( 'select' ).forEach( ( select: HTMLSelectElement ) => {
            for ( let index = 0; index < select.options.length; index++ ) {
                if ( select.options[ index ].disabled ) {
                    continue;
                }

                const holder = document.createElement( 'div' );
                holder.insertAdjacentHTML( 'beforeend', select.options[ index ].outerHTML );

                const option = ( holder.firstElementChild as HTMLOptionElement );

                if ( !option.selected && ( select.value.length > 0 && select.value !== option.value ) ) {
                    select.options[ index ].removeAttribute( 'selected' );
                    continue;
                }

                select.selectedIndex = index;
                select.value = select.options[ index ].value;

                select.setAttribute( 'value', select.options[ index ].value );

                select.options[ index ].setAttribute( 'selected', 'true' );

                if ( option.value.length > 0 ) {
                    select.classList.add( 'has-value' );
                } else {
                    select.classList.remove( 'has-value' );
                }
                break;
            }
        } );

        document.querySelectorAll<HTMLUListElement>( '.dropdown-menu[data-select-form][data-select-input-name]' ).forEach( ( dropdown: HTMLUListElement ) => {
            if ( !dropdown.dataset.selectForm || dropdown.dataset.selectForm.length === 0 ) {
                return;
            }

            const form: HTMLFormElement | null = document.querySelector( dropdown.dataset.selectForm );

            if ( !form ) {
                return;
            }

            if ( !dropdown.dataset.selectInputName || dropdown.dataset.selectInputName.length === 0 ) {
                return;
            }

            const input: HTMLInputElement | null = form.querySelector( `[name="${dropdown.dataset.selectInputName}"]` );

            if ( !input ) {
                return;
            }

            const selectedOption: HTMLButtonElement | null = dropdown.querySelector( '.active' );

            if ( !selectedOption || !selectedOption.dataset.value || selectedOption.dataset.value === input.value ) {
                return;
            }

            input.value = selectedOption.dataset.value;
        } );

        [
            'filterrating',
            'filterdistance',
            'monthselector',
        ].forEach( ( className: string ) => {
            document.querySelectorAll<HTMLLIElement>( `.${className} li` ).forEach( ( segment: HTMLLIElement ) => {
                const input = segment.querySelector<HTMLInputElement>( 'input' );

                if ( !input ) {
                    return;
                }

                const holder = document.createElement( 'div' );
                holder.insertAdjacentHTML( 'beforeend', segment.outerHTML );

                const segmentFromHTML = holder.firstElementChild as HTMLLIElement;

                if ( !segmentFromHTML.classList.contains( `${className}__group--active` ) ) {
                    input.removeAttribute( 'checked' );
                } else {
                    input.setAttribute( 'checked', 'true' );
                }
            } );
        } );

        document.querySelectorAll<HTMLInputElement>( '.ts-checktags input, .twc__switch input' ).forEach( ( tagInput: HTMLInputElement ) => {
            if ( tagInput.checked ) {
                tagInput.setAttribute( 'checked', 'true' );
            } else {
                tagInput.removeAttribute( 'checked' );
            }
        } );
    }
}

export default onPageShow;
