import { initGenericForms } from '../simpleform';
import { getHTML, getUtilityPopupContainer } from './utility-popups';
import { closeAllUtilityPopups, openUtilityPopup } from './utils';

export default function initPopupOpener(): void {
    document.addEventListener( 'click', async ( event: Event ) => {
        if ( !event.target || !( event.target instanceof HTMLElement ) ) {
            return;
        }

        const target = event.target as HTMLElement;

        if ( !target.dataset.popup ) {
            return;
        }

        const popupID = target.dataset.popup;

        let popupElement = document.querySelector<HTMLElement>( `.popup__container--${popupID}` );

        if ( !popupElement ) {
            const popupContainer = getUtilityPopupContainer();
            popupElement = await getHTML( popupID );
            popupContainer.appendChild( popupElement );
            initGenericForms( popupElement );
        }

        openUtilityPopup( popupElement );
    } );

    document.addEventListener( 'click', ( event: Event ) => {
        if ( !event.target || !( event.target instanceof HTMLElement ) ) {
            return;
        }

        const target = event.target as HTMLElement;

        if ( !target.dataset.action || target.dataset.action !== 'close-utility-popup' ) {
            return;
        }

        closeAllUtilityPopups();
    } );
}
