import Logger from '../logging/logger';
import { yieldToMainThread } from '../yield';
import initDirectoryEnquiryForm from '../directory/enquiry';

export type NewContentLoadedEvent = {
    container: HTMLElement | Document;
}

async function onNewContentLoaded( event: CustomEvent<NewContentLoadedEvent> ) {
    const detail = event.detail || {};
    const container = detail.container || document;

    Logger.info( 'Event Triggered: onNewContentLoaded', detail );

    const directoryEnquiryForms = document.querySelectorAll<HTMLFormElement>( '.directory-enquiry' );

    for ( let directoryForm = 0; directoryForm < directoryEnquiryForms.length; directoryForm++ ) {
        await yieldToMainThread();
        initDirectoryEnquiryForm( directoryEnquiryForms[ directoryForm ] );
    }

    // Set Saved Status for newly loaded content
    window.dispatchEvent( new CustomEvent( 'twc:update-saved-status', {
        bubbles: true,
        detail: {
            container: container,
        },
    } ) );
}

export default onNewContentLoaded;
