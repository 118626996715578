const svgs = {
    'closeX': ( fillName: string = 'white' ) => `<svg viewbox="0 0 25 25" width="21" height="21" class="v-fill v-fill-${fillName}"><use xlink:href="#icon-close"/></svg>`,
};

export default function getIcon( name: string, fillName: string ): string {
    return svgs[ name ]( fillName );
}

export function tryGetIconID( svg: SVGAElement ): string {
    if ( svg.id ) {
        return svg.id;
    }

    const useElement = svg.querySelector( 'use' ) as SVGUseElement | null;

    if ( useElement ) {
        return ( useElement.getAttribute( 'xlink:href' ) as string ).replace( '#icon-', '' );
    }

    const idClass = Array.from( svg.classList ).find( ( className: string ) => className.startsWith( 'icon-' ) );

    if ( idClass ) {
        return idClass.replace( 'icon-', '' );
    }

    return '';
}
