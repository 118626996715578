import Pristine from 'pristinejs';
import { addRecaptchaField, getCaptchaAction, loadRecaptcha } from '../captcha/google';
import { getDeviceInfo } from '../user/device-info';
import newValidator, { FormValidationError } from '../validation';
import { setupEmailSuggestionFromResult } from '../validation/email-suggest';
import triggerEvent, { GA4Event, GAEventName } from '../analytics/ga/events';
import { ErrorType, ErrorEvent } from '../analytics/ga/events';
import { fillTemplatedEventsFromFormSubmission, TemplateAnalyticsHeader } from '../analytics/http';
import { FormUpdateFrequency, FormUpdateMailings, getFormInfo } from '../forms/types';
import { NewContentLoadedEvent } from '../events/new-content-loaded';
import Logger from '../logging/logger';

const handledForms: Array<string> = [
    FormUpdateMailings,
    FormUpdateFrequency,
];

export function initGenericForm( form: HTMLFormElement ) {
    let validator: Pristine | null = null;

    if ( form.dataset.initialised && form.dataset.initialised === 'true' ) {
        return;
    }

    form.dataset.initialised = 'true';

    const formInfo = getFormInfo( form );

    Logger.info( 'initialised new generic form', formInfo.name );

    if ( form.dataset.simpleFormValidate && form.dataset.simpleFormValidate === 'true' ) {
        validator = newValidator( form );
    }

    const handleForm = async function( event: SubmitEvent ) {
        event.preventDefault();

        if ( form.dataset.isProcessing && form.dataset.isProcessing === 'true' ) {
            return;
        }

        const messageContainer = form.querySelector( '.messages' );

        if ( !messageContainer ) {
            throw new Error( 'failed to find message container for form' );
        }

        messageContainer.innerHTML = '';

        form.dataset.isProcessing = 'true';

        const submitBtn = form.querySelector( 'button[type=submit]' ) as HTMLButtonElement;

        if ( !submitBtn ) {
            throw new Error( 'failed to find form submit button' );
        }

        submitBtn.disabled = true;
        submitBtn.classList.add( 'btn--loading' );

        if ( validator !== null ) {
            const valid = validator.validate();

            const errors: Array<FormValidationError> = validator.getErrors();

            if ( errors.length > 0 ) {
                errors.forEach( ( err: FormValidationError ) => {
                    triggerEvent<ErrorEvent>( GAEventName.Error, {
                        'error_type': ErrorType.ErrForm,
                        'error_message': err.errors[ 0 ],
                    } );
                } );
            }

            if ( !valid ) {
                form.dataset.isProcessing = 'false';
                submitBtn.disabled = false;
                submitBtn.classList.remove( 'btn--loading' );
                return;
            }
        }

        const reset = function() {
            form.querySelectorAll( 'input' ).forEach( ( formInput: HTMLInputElement ) => {
                if ( formInput.type === 'hidden' ) {
                    return;
                }

                if ( formInput.type === 'checkbox' ) {
                    formInput.checked = false;
                } else if ( formInput.value && formInput.value.length > 0 ) {
                    formInput.value = '';
                }
            } );

            form.querySelectorAll( 'select' ).forEach( ( select: HTMLSelectElement ) => {
                if ( select.dataset.defaultSelected ) {
                    select.value = select.dataset.defaultSelected;
                } else {
                    select.value = '';
                }
            } );

            form.querySelectorAll( 'textarea' ).forEach( ( txt: HTMLTextAreaElement ) => {
                if ( txt.dataset.defaultValue && txt.dataset.defaultValue.length > 0 ) {
                    txt.value = txt.dataset.defaultValue;
                } else {
                    txt.value = '';
                }
            } );
        };

        const sendRequest = async function() {
            const formData = new FormData( form );
            formData.append( 'deviceinfo', JSON.stringify( getDeviceInfo() ) );

            const url = new URL( window.location.origin + form.getAttribute( 'action' ) );

            const response = await window.http( url, {
                method: 'POST',
                body: formData,
            } );

            form.dataset.isProcessing = 'false';
            submitBtn.classList.remove( 'btn--loading' );

            if ( response.headers.get( TemplateAnalyticsHeader ) ) {
                const eventTemplates = JSON.parse( response.headers.get( TemplateAnalyticsHeader ) as string );

                const events = fillTemplatedEventsFromFormSubmission( eventTemplates, {
                    form: form,
                    event: event,
                } );

                events.forEach( async ( event: GA4Event ) => {
                    await triggerEvent( event.event, event.parameters );
                } );
            }

            if ( !response.ok ) {
                throw new Error( `request for ${url} failed with status ${response.status}` );
            }

            const data = await response.json();

            const successful = ( data.success || data.refresh || data.redirect );

            const handled = handledForms.includes( formInfo.name );

            if ( successful && handled ) {
                document.dispatchEvent( new CustomEvent( 'twc:' + formInfo.name, {
                    bubbles: true,
                    detail: {
                        formName: formInfo.name,
                        form: form,
                    },
                } ) );
            }

            if ( data.success && form.dataset.simpleFormResetOnSubmit && form.dataset.simpleFormResetOnSubmit === 'true' ) {
                reset();
            }

            if ( data.success && data.content ) {
                submitBtn.disabled = false;
                document.body.insertAdjacentHTML( 'beforeend', data.content );
            }

            const removeSubmitOnSuccess = form.dataset.simpleFormBlockOnSuccess === 'true';

            if ( data.success && !data.content && !removeSubmitOnSuccess ) {
                messageContainer.insertAdjacentHTML( 'beforeend', `<div class="alert alert-success simpleform__successmsg">${data.message}</div>` );
            }

            if ( data.success && removeSubmitOnSuccess ) {
                const submitBtnParent = submitBtn.parentElement;

                if ( !submitBtnParent ) {
                    throw new Error( 'failed to find parent element of submit button' );
                }

                submitBtn.classList.add( 'd-none' );

                const resetButton = document.createElement( 'button' );
                resetButton.type = 'button';
                resetButton.innerHTML = form.dataset.simpleFormBlockOnSuccessBtn as string;

                const msg = document.createElement( 'div' );
                msg.className = 'alert alert-success simpleform__successmsg mb-none';
                msg.innerHTML = data.message + ' ';
                msg.appendChild( resetButton );

                resetButton.addEventListener( 'click', ( event: Event ) => {
                    reset();
                    submitBtn.classList.remove( 'd-none' );
                    msg.remove();
                } );

                submitBtn.insertAdjacentElement( 'afterend', msg );
            }

            if ( data.success && form.dataset.simpleFormOnSucces && form.dataset.simpleFormOnSucces.length > 0 ) {
                const events = form.dataset.simpleFormOnSucces.split( ',' );
                events.forEach( ( event: string ) => form.dispatchEvent( new CustomEvent( event, { bubbles: true } ) ) );
            }

            if ( !data.success && data.refresh ) {
                window.location.reload();
            }

            if ( !data.success && data.redirect ) {
                window.location = data.redirect;
            }

            if ( !data.success && data.errors && data.errors.form ) {
                triggerEvent<ErrorEvent>( GAEventName.Error, {
                    'error_type': ErrorType.ErrServer,
                    'error_message': data.errors.form,
                } );

                messageContainer.insertAdjacentHTML( 'beforeend', `<div class="alert alert-danger">${data.errors.form}</div>` );
                window.scroll( 0, messageContainer.getBoundingClientRect().top + window.scrollY - 200 );
            }

            if ( data.didyoumean ) {
                setupEmailSuggestionFromResult( {
                    emailSuggestion: data.didyoumean,
                    html: data.didyoumeanhtml,
                }, form );
            }

            submitBtn.disabled = false;
        };

        const token = await loadRecaptcha( getCaptchaAction( form ) );

        addRecaptchaField( form, token );
        sendRequest();
    };

    form.addEventListener( 'submit', handleForm );
}

export function initGenericForms( container: HTMLElement | Document = document, addEventListener: boolean = false ): void {
    function init( c: HTMLElement | Document = document ) {
        const forms = c.querySelectorAll<HTMLFormElement>( '.twc__simple-form' );
        forms.forEach( ( form: HTMLFormElement ) => initGenericForm( form ) );
    }

    if ( addEventListener ) {
        window.addEventListener( 'twc:newcontentloaded', ( event: CustomEvent<NewContentLoadedEvent> ) => {
            let container: HTMLElement | Document = document;

            if ( event && event.detail && event.detail.container ) {
                container = event.detail.container;
            }

            init( container );
        } );
    }

    init( container );
}
