import Logger from '../../logging/logger';

export enum TWCEventName {
    Call = 'call',
}

export const validEventNames: Array<TWCEventName> = [
    TWCEventName.Call,
];

type TWCEventParameter = string | number | bigint | boolean | object;

export interface TWCEventParameters {
    [ key: string ]: TWCEventParameter;
}

export interface TWCEvent<T extends TWCEventParameters> extends BaseEvent<T> {
    eventName: TWCEventName;
}

type BaseEvent<T extends TWCEventParameters> ={
    [ key in TWCEventName ]: T;
}

export type TWCCallEvent = {
    destination: string;
    originCountry: string;
    origins: Array<string>;
    itemID: number;
}

export function triggerTWCEvent<T extends TWCEventParameters>( eventName: TWCEventName, data: T ): void {
    if ( !window.twc ) {
        throw new Error( 'twc undefined' );
    }

    const event: TWCEvent<T> = {
        eventName: eventName,
        [ eventName ]: data,
    };

    const err: Error | null = validateEvent<T>( event );

    if ( err !== null ) {
        throw new Error( `event ${event.eventName} invalid, ${err.message}` );
    }

    Logger.info( `TWC analytics event: ${eventName}`, event );

    window.twc.track( `web_${eventName}`, event );
}

type ValidationFunc = ( event: TWCEventParameters ) => Error | null;

const validators: Record<TWCEventName, ValidationFunc> = {
    [ TWCEventName.Call ]: ( event: TWCCallEvent ): Error | null => {
        if ( !event.destination ) {
            return new Error( 'missing destination value' );
        }

        if ( !event.itemID ) {
            return new Error( 'missing itemID value' );
        }

        return null;
    },
};

export function validateEvent<T extends TWCEventParameters>( event: TWCEvent<T> ): Error | null {
    if ( !validEventNames.includes( event.eventName ) ) {
        return new Error( `unknown event "${event.eventName}"` );
    }

    const eventObj = event[ event.eventName ];

    if ( !eventObj ) {
        return new Error( `no event obj found for event ${event.eventName}` );
    }

    const validator: ValidationFunc = validators[ event.eventName ];

    if ( validator ) {
        const err = validator( event[ event.eventName ] );

        if ( err !== null ) {
            return err;
        }
    }

    return null;
}
