import Logger from '../../logging/logger';
import { initGlobalAnalyticClickEvents } from './click-events';
import { initGlobalAnalyticEventHandler } from './global-event-handler';

export function initGoogleAnalyticHandlers(): void {
    if ( window.baseAnalyticEventParameters ) {
        Logger.info( 'Initialising analytis with base parameters', window.baseAnalyticEventParameters );
    }

    Logger.info( 'Initialising analytic click events' );

    initGlobalAnalyticClickEvents();

    Logger.info( 'Initialising single-click event handling' );

    initGlobalAnalyticEventHandler();
}
