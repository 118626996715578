export const GAParameterContentGroup    = 'content_group';
export const GAParameterContentCategory = 'content_category';
export const GAParameterContentSection  = 'content_section';

export const GAParameterErrorType       = 'error_type';
export const GAParameterErrorMessage    = 'error_message';
export const GAParameterErrorLocation   = 'error_location';

export const GAParameterFormType        = 'form_type';
export const GAParameterFormLocation    = 'form_location';

export const GAParameterEmailTypes              = 'email_types';
export const GAParameterEmailFrequency          = 'email_frequency';
export const GAParameterTriggerBlockGroup       = 'trigger_block_group';
export const GAParameterTriggerTimeout          = 'trigger_timeout';
export const GAParameterContactMethods          = 'contact_methods';
export const GAParameterEmailUnsubscribeReason  = 'email_unsubscribe_reason';

export const GAParameterEnagagedLabel   = 'engaged_label';
export const GAParameterEnagagedElement = 'engaged_element';
export const GAParameterEnagagedGroup   = 'engaged_group';
export const GAParameterEnagagedTarget  = 'engaged_target';

export const GAParameterUserID          = 'user_id';
export const GAParameterUserLoginMethod = 'user_login_method';
export const GAParameterUserLoginStatus = 'user_login_status';
export const GAParameterUserEmailStatus = 'user_email_status';

export const GAParameterItemID          = 'item_id';
export const GAParameterItemName        = 'item_name';
export const GAParameterItemBrand       = 'item_brand';
export const GAParameterItemCategory1   = 'item_category';
export const GAParameterItemCategory2   = 'item_category2';
export const GAParameterItemCategory3   = 'item_category3';
export const GAParameterItemCategory4   = 'item_category4';
export const GAParameterItemCategory5   = 'item_category5';
export const GAParameterItemListID      = 'item_list_id';
export const GAParameterItemListName    = 'item_list_name';
export const GAParameterItemVariant     = 'item_variant';
export const GAParameterLocationID      = 'location_id';

export const GAParameterCategory = 'category';
export const GAParameterSlug = 'slug';
export const GAParameterTopics = 'topics';
export const GAParameterContentRating = 'content_rating';

export const GAParameterLanguage = 'lang';
export const GAParameterCurrency = 'currency';

export const GAParameterIndex = 'index';
export const GAParameterPrice = 'price';
export const GAParameterQuantity = 'quantity';

export const GAParameterShareMethod = 'share_method';

export const GAParameterAction = 'action';

export const GAParameterLeadID = 'lead_id';

export const GAParameterRegistrationMethod = 'registration_method';

export const ValidGAParameters = [
    GAParameterContentGroup,
    GAParameterContentCategory,
    GAParameterContentSection,
    GAParameterErrorType,
    GAParameterErrorMessage,
    GAParameterErrorLocation,
    GAParameterFormType,
    GAParameterFormLocation,
    GAParameterEmailTypes,
    GAParameterEmailFrequency,
    GAParameterEmailUnsubscribeReason,
    GAParameterTriggerBlockGroup,
    GAParameterTriggerTimeout,
    GAParameterContactMethods,
    GAParameterEnagagedLabel,
    GAParameterEnagagedElement,
    GAParameterEnagagedGroup,
    GAParameterEnagagedTarget,
    GAParameterUserID,
    GAParameterUserLoginMethod,
    GAParameterUserLoginStatus,
    GAParameterUserEmailStatus,
    GAParameterShareMethod,
    GAParameterAction,
    GAParameterLanguage,
    GAParameterCurrency,
    GAParameterItemID,
    GAParameterItemName,
    GAParameterItemBrand,
    GAParameterItemCategory1,
    GAParameterItemCategory2,
    GAParameterItemCategory3,
    GAParameterItemCategory4,
    GAParameterItemCategory5,
    GAParameterItemListID,
    GAParameterItemListName,
    GAParameterItemVariant,
    GAParameterLocationID,
    GAParameterIndex,
    GAParameterPrice,
    GAParameterContentRating,
    GAParameterCategory,
    GAParameterTopics,
    GAParameterSlug,
    GAParameterLeadID,
    GAParameterRegistrationMethod,
];
