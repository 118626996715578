import * as Sentry from '@sentry/browser';
import { getState } from '../routing/history';
import { getPagePopupContainer } from '../popups/page-popup';
import Logger from '../logging/logger';

function onPopupOpen( event: CustomEvent ): void {
    let detail = event.detail.srcEvent || {};

    if ( event.detail.useHistory && event.detail.useHistory === true ) {
        detail = history.state;
    }

    Logger.info( 'Event Triggered: onPopupOpen %O', detail );

    let srcURL: URL | null = null;

    try {
        srcURL = new URL( detail.url );
    } catch ( err ) {
        srcURL = null;
        throw err;
    }

    let elementToScrollTo: HTMLElement | null = null;

    const shouldScroll = srcURL && srcURL.searchParams.has( 'scrollTo' ) && srcURL.searchParams.get( 'scrollTo' );

    if ( shouldScroll ) {
        elementToScrollTo = document.getElementById( srcURL.searchParams.get( 'scrollTo' ) as string );
    }

    if ( shouldScroll && elementToScrollTo !== null && !detail.isRoutedPopup ) {
        elementToScrollTo.scrollIntoView( true );
        Logger.info( 'Action Triggered: scroll to target, popup=no %O', detail );
    }

    const popupContainer = getPagePopupContainer();

    const elementToScroll = popupContainer.querySelector( '.pagepop__scrollcontainer' );

    if ( !elementToScroll ) {
        Sentry.captureException( new Error( 'find to find popup scroll container' ) );
        return;
    }

    let positionToScrollTo = 0;

    if ( shouldScroll && elementToScrollTo !== null && detail.isRoutedPopup ) {
        const rect: DOMRect = elementToScrollTo.getBoundingClientRect();
        const header = popupContainer.querySelector( '.pagepopup__header' ) as HTMLElement;

        positionToScrollTo = rect.y - header.offsetHeight;
        Logger.info( `Action Triggered: scroll to target (${positionToScrollTo}), popup=yes` );
    }

    if ( !shouldScroll && !elementToScrollTo ) {
        positionToScrollTo = detail.scrollPosition;
        Logger.info( `Action Triggered: scroll to (${detail.scrollPosition}) default %O`, detail );
    }

    window.requestAnimationFrame( async () => {
        elementToScroll.scrollTop = positionToScrollTo;
        await captureScrollPosition();
    } );
}

async function captureScrollPosition(): Promise<void> {
    window.router.Replace( 'current', Object.assign( {}, getState(), {
        scrollPosition: window.router.GetScrollOffset().value,
    } ) );
}

export function getOnPopupScroll(): EventListener {
    let scrollingTimeout: number | null = null;
    return function( event: Event ): void {
        if ( scrollingTimeout !== null ) {
            window.clearTimeout( scrollingTimeout );
        }

        scrollingTimeout = window.setTimeout( async () => {
            await captureScrollPosition();
            scrollingTimeout = null;
        }, 250 );
    };
}

export default onPopupOpen;
